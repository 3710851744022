<template>
   <com-page-main>
      <div class="article">
         <iframe
            :src="`/html/${this.$route.query.id}.html`"
            align="middle"
            frameborder="0"
         ></iframe>
      </div>
   </com-page-main>
</template>

<script>
import ComPageMain from "@/components/PageMain";

export default {
   name: "PagePosts",
   components: {
      ComPageMain,
   },
   data() {
      return {};
   },
   created() {},
   methods: {},
};
</script>

<style lang="scss">
iframe {
   border: none;
   width: 980px;
   height: 500px;
   overflow-y: auto;
}
.article {
   max-width: 900px;
   margin: 0 auto;
   min-height: 300px;

   &-title {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      color: var(--color-title);
   }

   &-des {
      margin-top: 5px;
      font-size: 12px;
      text-align: center;
      color: var(--color-fun);
   }

   &-content {
      margin-top: 20px;
      font-size: 16px;

      p {
         margin: 1.5em 0;
         color: var(--color-normarl);
         text-indent: 2em;
      }

      img {
         max-width: 100%;
         width: auto !important;
         height: auto !important;
         margin: 0 auto;
      }
   }
}
</style>
