<template>
   <com-page-main>
      <div v-loading="loading" class="article">
         <div class="article-title">{{ title }}</div>
         <div class="article-des">{{ date }}</div>
         <div class="article-content" v-html="content"></div>
      </div>
   </com-page-main>
</template>

<script>
import { http } from "@/http";
import ComPageMain from "@/components/PageMain";

export default {
   name: "Pagearticle",
   components: {
      ComPageMain,
   },
   data() {
      return {
         // 标题
         title: "",
         // 日期
         date: "",
         // 内容
         content: "",
         // 加载效果
         loading: false,
      };
   },
   created() {
      this.getData();
   },
   methods: {
      htmlspecialchars_decode(str) {
         str = str.replace(/&amp;/g, "&");
         str = str.replace(/&lt;/g, "<");
         str = str.replace(/&gt;/g, ">");
         str = str.replace(/&quot;/g, "\"");
         return str;
      },
      /**
       * 获取数据
       */
      getData() {
         this.loading = true;

         http
            .get(`post?id=${this.$route.query.id}`)
            .then((res) => {
               const { title, date, content } = res.data;

               this.title = title;
               this.date = date;
               this.content = this.htmlspecialchars_decode(content);
               // this.content = content;
            })
            .finally(() => {
               this.loading = false;
            });
      },
   },
};
</script>

<style lang="scss">
.article {
   max-width: 900px;
   margin: 0 auto;
   min-height: 300px;

   &-title {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      color: var(--color-title);
   }

   &-des {
      margin-top: 5px;
      font-size: 12px;
      text-align: center;
      color: var(--color-fun);
   }

   &-content {
      margin-top: 20px;
      font-size: 16px;

      p {
         margin: 1.5em 0;
         color: var(--color-normarl);
         text-indent: 2em;
      }

      img {
         max-width: 100%;
         width: auto !important;
         height: auto !important;
         margin: 0 auto;
      }
   }
}
</style>
